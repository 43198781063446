import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const DotsBox = styled.div`
  width: 100px;
  height: 20px;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 10%;
  bottom: 30px;
  z-index: 6;
  @media (max-width: 991px) {
    right: 0%;
    bottom: 10px;
  }
`

const DotsContainer = ({ children }) => {
  return <DotsBox>{children}</DotsBox>
}

DotsContainer.propTypes = {
  children: PropTypes.array,
}

export default DotsContainer
