import React from "react"
import styled from "styled-components"
import PageHeader from "../atoms/pageHeader"
import Other from "../molecules/other"
import { useStaticQuery, graphql } from "gatsby"

const OthersBox = styled.div`
  width: 70vw;
  min-width: 350px;
  position: relative;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 150px;
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    width: 90vw;
  }
`

// const ButtonBox = styled.div`
//   margin: auto;
//   margin-top: 80px;
//   @media (max-width: 575px) {
//     margin-left: 0;
//     margin-right: 0;
//   }
// `

const Others = () => {
  const data = useStaticQuery(query)

  return (
    <OthersBox id="others">
      <PageHeader>Nasze inwestycje</PageHeader>
      {data.allDatoCmsOther.edges.map((otherData, index) => (
        <Other key={index} data={otherData.node} />
      ))}
    </OthersBox>
  )
}

const query = graphql`
  query AllOthers {
    allDatoCmsOther(sort: { order: ASC, fields: orderby }) {
      edges {
        node {
          dostepny
          link
          opis
          podtytul
          actualStatus
          tytul
          zdjecie {
            gatsbyImageData
          }
          orderby
        }
      }
    }
  }
`

export default Others
