import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Paragraph from "../atoms/paragraph"

const ContactBox = styled.div`
  width: 200px;
  background-color: ${({ theme }) => theme.colors.secondary};
  position: absolute;
  padding: 5px 40px 5px 5px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  opacity: 0;
  top: 0;
  right: 0;
  @media (max-width: 575px) {
    width: 100%;
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: flex-start;
  }
`

const Icon = styled.img`
  max-width: 30px;
  margin: 10px;
  @media (max-width: 575px) {
    margin-right: 20px;
    margin-left: 30px;
  }
`

const ParagraphTop = styled(Paragraph)`
  color: white;
  font-size: 0.7rem;
  opacity: 0.7;
  margin-bottom: 0;
`
const PhoneNumber = styled(Paragraph)`
  color: white;
  font-size: 1.1rem;
  margin-top: 0;
  text-decoration: none;
  color: white;
  @media (max-width: 575px) {
    margin-bottom: 10px;
  }
`

const Contact = () => {
  const data = useStaticQuery(query)

  return (
    <ContactBox data-aos="fade-down" data-aos-duration="1200">
      <Icon src={data.file.publicURL} alt="Phone icon" />
      <div>
        <ParagraphTop>Zapraszamy do kontaktu</ParagraphTop>
        <PhoneNumber>+48 888 088 888</PhoneNumber>
      </div>
    </ContactBox>
  )
}

const query = graphql`
  query PhoneIcon {
    file(relativePath: { eq: "Icons/icon-phone-light.svg" }) {
      id
      extension
      publicURL
    }
  }
`

export default Contact
