import React from "react"
import Map from "../molecules/map"
import styled from "styled-components"
import ContactColumn from "../molecules/contactColumn"

const ContactBox = styled.div`
  width: 70vw;
  min-width: 350px;
  position: relative;
  margin: auto;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1279px) {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  @media (max-width: 991px) {
    width: 90vw;
  }
  @media (max-width: 500px) {
    justify-content: center;
  }
`

const Contact = () => {
  return (
    <ContactBox>
      <Map position={{ lat: 53.820268304297514, lng: 22.347055986305225 }} zoom={15} />
      <ContactColumn />
    </ContactBox>
  )
}

export default Contact
