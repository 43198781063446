import React from "react"
import PropTypes from "prop-types"
import styled, { keyframes } from "styled-components"

const SlideIn = keyframes`
    0%{
        left: 100%;
    }

    100%{
        left: 0;
        display: none;
    }
`

const SlideOff = keyframes`
    0%{
        left: 0;
    }

    100%{
        left: -100%;
        display: none;
    }
`

const SlideOffRight = keyframes`
    0%{
        left: 0;
        z-index: -1;
    }

    100%{
        left: 0;
        z-index: -1;
        opacity: 0;
    }
`

const SlideBox = styled.div`
  position: absolute;
  left: 100%;
  transition: 0.4s ease;
  min-height: 575px;
  width: 100%;
  height: auto;
  animation: 1.5s ease forwards;
  animation-name: ${({ active, slideIndex, activeSlideIndex }) =>
    active === true ? SlideIn : slideIndex > activeSlideIndex ? SlideOffRight : SlideOff};
  & > div {
    height: 90vh;
    transition: none;
    min-height: 575px;
  }
`

const Slide = ({ children, active, onTouchStart, activeSlideIndex, slideIndex }) => (
  <SlideBox slideIndex={slideIndex} activeSlideIndex={activeSlideIndex} onTouchStart={onTouchStart} active={active}>
    {children}
  </SlideBox>
)

Slide.propTypes = {
  children: PropTypes.element,
  active: PropTypes.bool,
  onTouchStart: PropTypes.func,
  activeSlideIndex: PropTypes.number,
  slideIndex: PropTypes.number,
}

export default Slide
