import React from "react"
import styled from "styled-components"
import GoogleMapReact from "google-map-react"
import { mapStyles } from "../atoms/mapStyles"
import pin from "../../assets/images/Icons/pin.png"
import PropTypes from "prop-types"

const MapBox = styled.div`
  width: 50vw;
  height: 600px;
  display: block;
  margin-left: -22%;
  left: 0;
  margin-right: 40px;
  background-color: grey;
  position: relative;
  @media (max-width: 1279px) {
    width: 100vw;
    margin: auto;
  }
  @media (max-width: 991px) {
    height: 500px;
  }
  @media (max-width: 630px) {
    width: 100vw;
    height: 300px;
  }
`
const PinBox = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -100%);
`

const Pin = () => (
  <PinBox>
    {" "}
    <img src={pin} alt="pin" />{" "}
  </PinBox>
)

const options = {
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
  scrollWheel: false,
  styles: mapStyles,
}

const Map = ({ position, zoom }) => {
  return (
    <MapBox data-aos="fade-up" data-aos-duration="800" data-aos-delay="200">
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.GATSBY_API_KEY }}
        zoom={zoom}
        options={options}
        center={[position.lat, position.lng]}
      >
        <Pin lat={position.lat} lng={position.lng} text="My Marker" />
      </GoogleMapReact>
    </MapBox>
  )
}

Map.propTypes = {
  position: PropTypes.object,
  zoom: PropTypes.number,
}

export default Map
