import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const DotBox = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 100%;
  margin-left: 5px;
  background-color: ${({ active, theme }) => (active ? theme.colors.primary : "none")};
  border: 2px solid ${({ active, theme }) => (active ? theme.colors.primary : "white")};
  opacity: ${({ active }) => (active ? 1 : 0.8)};
  z-index: 1;
  @media (max-width: 575px) {
    width: 10px;
    height: 10px;
  }
`

const Dot = ({ onClick, active }) => {
  return <DotBox active={active} onClick={onClick}></DotBox>
}

Dot.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.bool,
}

export default Dot
