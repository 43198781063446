import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import AnchorLink from "../atoms/anchorLink"
import ParagraphSmall from "../atoms/paragraphSmall"
import Link from "../atoms/link"
import { GatsbyImage } from "gatsby-plugin-image"

const OtherBox = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.lightGray};
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: space-around;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 20px;
  border: none;
`

const ImageBox = styled.div`
  width: 31%;
  min-width: 300px;
  height: 250px;
  margin-top: 15px;
  margin-left: 10px;
  @media (max-width: 1323px) {
    width: 50%;
    height: 300px;
  }
  @media (max-width: 991px) {
    width: 80%;
    margin-top: 0;
    margin-left: 0;
  }
`

const Image = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`

const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1324px) {
    display: none;
  }
`

const Button = styled(AnchorLink)`
  background-color: black;
  min-width: 120px;
  color: white;
  padding: 20px 60px;
  border-radius: 90px;
  border-color: black;
  display: inline-block;
  transition: 0.2s ease-in-out;
  margin-right: 10px;
  cursor: pointer;
  text-align: center;
  &:hover {
    filter: brightness(90%);
  }
  @media (max-width: 770px) {
    display: block;
    margin-top: 5px;
    text-align: center;
  }
  @media (max-width: 575px) {
    width: 60%;
    margin: auto;
    margin-top: 10px;
  }
`

const HiddenButton = styled(Button)`
  display: none;
  @media (max-width: 1324px) {
    display: inline-block;
    width: 150px;
    margin-bottom: 20px;
  }
`

const GatsbyLink = styled(Link)`
  background-color: black;
  min-width: 120px;
  color: white;
  padding: 20px 60px;
  border-radius: 90px;
  border-color: black;
  display: inline-block;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  margin-right: 10px;
  cursor: pointer;
  text-align: center;
  &:hover {
    filter: brightness(90%);
  }
  @media (max-width: 770px) {
    display: block;
    margin-top: 5px;
    text-align: center;
  }
  @media (max-width: 575px) {
    width: 60%;
    margin: auto;
    margin-top: 10px;
  }
`

const HiddenGatsbyLink = styled(GatsbyLink)`
  display: none;
  @media (max-width: 1324px) {
    display: inline-block;
    width: 150px;
    margin-bottom: 20px;
  }
`

const ContentBox = styled.div`
  width: 35%;
  @media (max-width: 1279px) {
    margin-left: 0px;
  }
  @media (max-width: 991px) {
    width: 80%;
    margin-left: 0;
  }
`
const Header = styled.h2`
  font-weight: 300;
  font-size: 2rem;
`

const SubHeader = styled.h3`
  font-weight: 100;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 0;
`

const Other = ({ data }) => {
  return (
    <OtherBox data-aos="fade-up" data-aos-duration="1200">
      <ImageBox>
        <AnchorLink to={data.link ? data.link : data.dostepny === true ? "/#contact" : "/#others"}>
          <Image image={data.zdjecie.gatsbyImageData} alt={data.tytul} key={data.podtytul} />
        </AnchorLink>
      </ImageBox>
      <ContentBox>
        <AnchorLink to={data.link ? data.link : data.dostepny === true ? "/#contact" : "/#others"}>
          <Header>{data.tytul}</Header>
        </AnchorLink>
        <SubHeader>{data.podtytul}</SubHeader>
        <ParagraphSmall>{data.opis}</ParagraphSmall>

        {data.link ? (
          <HiddenGatsbyLink href={data.link}>{data.actualStatus}</HiddenGatsbyLink>
        ) : (
          <HiddenButton style={{ color: "white" }} to={data.dostepny === true ? "/#contact" : "/#others"}>
            {data.actualStatus}
          </HiddenButton>
        )}
      </ContentBox>
      <ButtonBox>
        {data.link ? (
          <GatsbyLink href={data.link}>{data.actualStatus}</GatsbyLink>
        ) : (
          <Button style={{ color: "white" }} to={data.dostepny === true ? "/#contact" : "/#others"}>
            {data.actualStatus}
          </Button>
        )}
      </ButtonBox>
    </OtherBox>
  )
}

Other.propTypes = {
  data: PropTypes.object,
}

export default Other
