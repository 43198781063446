import React from "react"
import PropTypes from "prop-types"
import LoadingGif from "../../assets/images/loading.gif"
import styled from "styled-components"

const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ show }) => (show ? 1 : 0)};
  z-index: ${({ show }) => (show ? 5 : -3)};
  transition: 0.3s ease-in;
`

const Loading = ({ display }) => {
  return (
    <LoadingContainer show={display}>
      <img src={LoadingGif} alt="Loading" />
    </LoadingContainer>
  )
}

Loading.propTypes = {
  display: PropTypes.bool,
}

export default Loading
