import React from "react"
import styled from "styled-components"
import PageHeader from "../atoms/pageHeader"
import ParagraphSmall from "../atoms/paragraphSmall"
import Paragraph from "../atoms/paragraph"
import Link from "../atoms/link"
import TextHeader from "../atoms/textHeader"

const ContactColumnBox = styled.div`
  display: block;
  max-width: 35%;
  min-width: 350px;
  @media (max-width: 1279px) {
    max-width: 60%;
    margin-top: 40px;
    margin-bottom: 50px;
    min-width: 350px;
  }
  @media (max-width: 500px) {
    margin-left: 0;
  }
`

const ContactColumn = () => {
  return (
    <ContactColumnBox data-aos="fade-right" id="contact">
      <PageHeader>Kontakt</PageHeader>
      <ParagraphSmall style={{ marginBottom: 0 }}>
        Zapraszamy do kontaktu <br /> Marcin Brochacki{" "}
      </ParagraphSmall>
      <TextHeader style={{ margin: 0, fontSize: `2rem`, textDecoration: "none", color: "black" }}>
        +48 888 088 888
      </TextHeader>
      <ParagraphSmall>Adres e-mail</ParagraphSmall>
      <Link href="mailto:marcin@domset.pl" color="#21c5c6">
        marcin@domset.pl
      </Link>
      <Paragraph style={{ fontWeight: "bold", marginBottom: 0 }}>Dział sprzedaży</Paragraph>
      <ParagraphSmall style={{ marginTop: 0 }}>(przed spotkaniem prosimy o telefon)</ParagraphSmall>
      <ParagraphSmall>
        DOMSET Nieruchomości <br /> ul. Wojska Polskiego 43/lok3 <br /> 19-300 Ełk
      </ParagraphSmall>
    </ContactColumnBox>
  )
}

export default ContactColumn
